import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios'

export const CategoryFetch = createAsyncThunk(
  'category/CategoryFetch',

  async (_, { rejectWithValue }) => {
    try {
      const res = await axios.get(`https://sgtback.pythonanywhere.com/api/v1/shop/category/`);
      return res.data;
      
    } catch (error) {
      return rejectWithValue(error.message);
    }

  }
)
export const BrandFetch = createAsyncThunk(
  'brand/BrandFetch',

  async (_, { rejectWithValue }) => {
    try {
      const res = await axios.get(`https://sgtback.pythonanywhere.com/api/v1/shop/brand/`);
      return res.data;
      
    } catch (error) {
      return rejectWithValue(error.message);
    }

  }
)
export const MakeFetch = createAsyncThunk(
  'make/MakeFetch',

  async (_, { rejectWithValue }) => {
    try {
      const res = await axios.get(`https://sgtback.pythonanywhere.com/api/constructor/computer/`);
      return res.data;
      
    } catch (error) {
      return rejectWithValue(error.message);
    }

  }
)
export const Minefarm = createAsyncThunk(
  'minefarm/Minefarm',

  async (_, { rejectWithValue }) => {
    try {
      const res = await axios.get(`https://sgtback.pythonanywhere.com/api/constructor/minefarm/`);
      return res.data;
      
    } catch (error) {
      return rejectWithValue(error.message);
    }

  }
)

const setError = (state, action) => {
  state.status = 'failed';
  state.error = action.error.message;
}


const CategorySlice = createSlice({
  name: "category",
  initialState: {
    category:[],
    brand:[],
    makes:[],
    Minefarm:[],
    loading: null,
    error: null
  },
  reducers: {
  },
  
  extraReducers: {
    [CategoryFetch.pending]: (state) => {
      state.status = 'loading';
      state.error = null;
    },
    [BrandFetch.pending]: (state) => {
      state.status = 'loading';
      state.error = null;
    },
    [MakeFetch.pending]: (state) => {
      state.status = 'loading';
      state.error = null;
    },
    [Minefarm.pending]: (state) => {
      state.status = 'loading';
      state.error = null;
    },



    [CategoryFetch.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.category = action.payload;
    },
    [BrandFetch.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.brand = action.payload;
    },
    [MakeFetch.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.makes = action.payload;
    },
    [Minefarm.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.Minefarm = action.payload;
    },

    [CategoryFetch.rejected]: setError,
    [BrandFetch.rejected]: setError,
    [MakeFetch.rejected]: setError,
    [Minefarm.rejected]: setError,
  }
  
});

export default CategorySlice.reducer;
// export const {} = CategorySlice.actions;