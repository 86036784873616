import React from 'react'
import Basketbox from '../components/basket/Basket'

const Basket = () => {
  return (
    <div>
      <Basketbox/>
    </div>
  )
}

export default Basket
