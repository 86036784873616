import React from 'react';
import OtherCategory1 from '../components/category/category'

const Category = () => {
  return (
    <>
        <div className="section">
          <OtherCategory1 />
        </div>
    </>
  )
};

export default Category;