import React from 'react'
import cl from './category.module.css'
import {useSelector} from 'react-redux'

const MyModal = ({children, Catmodal, setCatModal}) => {
  
  const count = useSelector(state => state.toolkit.modal)
  // console.log("Modal", count)
  const rootClasses = [cl.myModal]

  if(Catmodal){
    rootClasses.push(cl.active)
  }

  const removeModal =()=>{
    setCatModal(false)
  }

  return (
    <div className={rootClasses.join(' ')} onClick={removeModal}>
      <div className={cl.modalContent} onClick={e => e.stopPropagation()}>
        {children}
      </div>
    </div>
  )
}

export default MyModal