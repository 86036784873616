import React from "react";
import './slider.css'
import Slider from "react-slick";
import sliderIMG1 from '../../images/Slider_back_2.png'
import sliderIMG2 from '../../images/Slider_back_1.png'
import sliderIMG3 from '../../images/Slider_back_3.png'
import {useTranslation} from 'react-i18next'
import FadeIn from 'react-fade-in'
const Sliders = () => {
  const {t} = useTranslation()
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    autoplaySpeed: 4000,
    autoplay: true,
  };
  return (
    <>
      <div className="container image_slider">
        <FadeIn>
        <Slider {...settings}>
          <div>
            <div className="images" style={{ backgroundImage: `url(${sliderIMG1})`,}}>
              <div><span>{t("..pcyeging")}</span></div>
            </div>
          </div>
          <div>
            <div className="images" style={{  backgroundImage: `url(${sliderIMG2})`, }}>
            <div><span>{t("..Fermangizni")}</span></div>
            </div>
          </div>
          {/* <div>
            <div className="images" style={{justifyContent:'center', alignItems:'center', backgroundImage: `url(${sliderIMG3})`,}}>
            <div className="get_span"><span>{t("..Fermangizni")}</span></div>
            </div>
          </div> */}
        </Slider>
        </FadeIn>
      </div>
    </>
  );
};
export default Sliders;
