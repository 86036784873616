import React from 'react';
import Slider from "../components/slider/Slider";
import StartPc from "../components/mycomputer/MyPc";
import Maps from "../components/map/Map";
import Card from '../components/cards/Card'
// const CardsComponent = React.lazy(() => import('../components/cards/Card'));

const Home = () => {
  return(
    <>
      <div className="section">
        <Slider />
        <Card/>
        <StartPc />
        <Maps />
      </div>
    </> 
    ) 
};

export default Home;
