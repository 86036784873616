import React, { useEffect, useState, useTransition } from 'react'
import classes from './navbar.module.css'
import MyButton from '../buttons/Button'
import BuyBox from '../../images/VectorBoxIcon.svg'
import Search from '../../images/VectorSeacrhIcon.svg'
import ModalCategory from '../categoryModal/Category'
import CategoryApp from '../categoryModal/ProdctCategoty'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux'
import Logo from '../../images/_2686037395568SGT_logotip.svg'
import '../../i18next'
import icon_catalog from '../../images/Group (3).svg'
import { useNavigate } from 'react-router-dom';
import FadeIn from 'react-fade-in'


const Navbar = () => {

  const navigate = useNavigate()
  const { i18n } = useTranslation();
  const language = localStorage.getItem("i18nextLng")
  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  }
  const { t } = useTranslation();
  const Products = useSelector((state) => state.toolkit.data.result)
  const basket = useSelector((state) => state.basket.basket)
  const [todo, setTodo] = useState([])
  const [Catmodal, setCatModal] = useState(false)
  const [searchvalue, setSearchValue] = useState('')


  useEffect(() => {
    const obj = localStorage.getItem("basket")
    setTodo(JSON.parse(obj))
  }, [])

  const [, startTransition] = useTransition()

  const getInput = (e) => {

    startTransition(() => {
      setSearchValue(e)
    })
  }

  const firstSearch = Products?.filter((item1) => {
    if (searchvalue === "") {
      return null;
    } else {
      return (
        item1.name.toLowerCase().includes(searchvalue.toLowerCase())
      );
    }
  });

  const productRedirect = (e) => {
    e.preventDefault()
    navigate(`/category/` + searchvalue)
    setSearchValue('')
  }
  return (
    <FadeIn>
      <div className="container">
        <div className="row">
          <div className={classes.search_top}>
            <div className={classes.search_left}>
              <Link to="/"><img src={Logo} alt="logo" /></Link>
              <MyButton onClick={() => setCatModal(true)}><img rel="preload" as="image" src={icon_catalog} alt="icon_slider" decoding="auto" /></MyButton>
            </div>
            <ModalCategory Catmodal={Catmodal} setCatModal={setCatModal}>
              <CategoryApp setCatModal={setCatModal} />
            </ModalCategory>
            <div className={classes.search_right}>
              <div className={classes.search_right_search}>
                {firstSearch?.length ? (
                  <div className={classes.value_searcher}>
                    {firstSearch?.map((item) => {
                      return (
                        <a href={"/product/" + item.id}>
                          <div>{item.name}</div>
                        </a>
                      )
                    })}
                  </div>
                ) : null}
                <form onSubmit={productRedirect}><input value={searchvalue} onChange={(e) => getInput(e.target.value)} type="text" placeholder={t("..qidiruv")} id="search" /></form> <label htmlFor='search' style={{ cursor: 'pointer', }}><img rel="preload" as="image" src={Search} alt="icon_slider" decoding="auto" /></label></div>
              <div className={classes.basket_icon}><Link to={"/basket"}>{basket.length == 0 ? null : <span className={classes.product_count} style={{ color: 'white' }}>{basket.length}</span>}<div><img rel="preload" as="image" src={BuyBox} alt="icon_slider" decoding="auto" /></div><span>{t("..savat")}</span></Link></div>
              <div className={classes.language}>{language === "ru" ? <span onClick={() => changeLanguage("uz")}>Uzb</span> : <span onClick={() => changeLanguage("ru")}>Ru</span>}</div>
            </div>
          </div>
        </div>
      </div>
    </FadeIn>
  )
}

export default Navbar
