import React, { useState, useEffect } from 'react'
import basket_box from '../../images/restore_from_trashbasket_box.svg'
import './basket.css'
import MyButton from '../buttons/Button'
import Payment from '../payment/Payment'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { removeBasketProduct, IncrementProduct, DecrementProduct, clearInfoProducts } from '../../redux/Basketslice'
import { Link } from 'react-router-dom'
import { removeLikes } from '../../redux/GetSlice'
import Payme from '../../images/payme-logo.png'
import Click from '../../images/Click.png'
import shopping_cart from '../../images/shopping_basket.png'
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios'
import FadeIn from 'react-fade-in'

const Basket = () => {
  const notify = (name, letter) => toast.warn(`${name} ${letter}`);
  const type = useSelector((state) => state.basket.InfoProducts)
  const basket = useSelector((state) => state.basket.basket)
  const [hide, setHide] = useState(false)
  const dispatch = useDispatch()
  const { t } = useTranslation()


  useEffect(() => {
    const res = axios.get("https://api.click.uz/v2/merchant/payment/status/20599/53328", {
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Auth": `${process.env.REACT_APP_MERCHAN_USER_ID}:sha1(${+new Date()}${process.env.REACT_APP_SECRET_KEY}):${parseInt(Date.now() / 1000)}`,
      }
    }).then((res) => console.log("CLICK_RES", res))
  }, [])

  // console.log("parseInt(Date.now() / 1000",parseInt(Date.now() / 1000));
  const RemoveBasket = (id, letter, name) => {
    notify(name, letter)
    dispatch(removeBasketProduct(id))
    dispatch(removeLikes(id))
  }

  const sum = basket.reduce(
    (accumulator, currentValue) => accumulator + currentValue.CounterPrice, 0
  );
  const productCounter = basket.reduce(
    (accumulator, currentValue) => accumulator + currentValue.count, 0
  );


  return (
    <div className='basket'>
      <ToastContainer position="top-center" style={{ minWidth: "350px", maxWidth: '600px' }}
        autoClose={2000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover />
      <FadeIn>
        <div className="container">
          <div className="row">
            <span className='basket_title'>{t("..savat")}</span>
            <div className="col-lg-9 left">
              {basket.length ? (
                basket?.map((product, index) => {
                  return (
                    <div className='basket_product' key={product.id}>
                      <div className='basket_column'>
                        <div><Link to={"/product/" + product.id}><img rel="preload" as="image" src={`https://sgtback.pythonanywhere.com` + product.image1} alt="icon_slider" decoding="auto" /></Link></div>
                        <div style={{ maxWidth: '350px' }}><span>{product.name}</span></div>
                      </ div>
                      <div className='basket_counter'>
                        <div>
                          <p className='d-none d-sm-block'>{Math.round(product.CounterPrice).toLocaleString()} cум</p>
                          <div className='basket_calc'>
                            <span onClick={() => dispatch(DecrementProduct(product))}>-</span>
                            <p>{product.count}</p>
                            <span onClick={() => dispatch(IncrementProduct(product))}>+</span>
                          </div>
                          <div><img onClick={() => RemoveBasket(product.id, t("..Savatda"), product.name)} rel="preload" as="image" src={basket_box} alt="icon_slider" decoding="auto" /></div>
                        </div>
                        <div>
                          <p className='d-block d-sm-none' style={{ marginRight: '30px' }}>{product.CounterPrice.toLocaleString()} cум</p>
                        </div>
                      </div>
                    </div>
                  )
                })
              ) : (
                <div className='basket_clear' style={{ textAlign: 'center' }}>
                  <img src={shopping_cart} alt="cart" />
                  <h2>{t("..basket1")}</h2>
                  <span>{t("..basket2")}</span>
                  <MyButton><Link to="/">{t("..basket3")}</Link></MyButton>
                </div>
              )}
              {hide ? <Payment sum={sum} productCounter={productCounter} /> : null}
            </div>
            <div className="col-lg-3 col-md-7 col-sm-12 right">
              <div className='right_basket_bg'>
                <span className='count'>{t("..Mahsulotlar")}</span>
                <div><span>{t("..Mahsulotlar")}:</span><p>{productCounter}</p></div>
                <div><span>{t("..price")}:</span><p>{Math.round(sum).toLocaleString()} сум</p></div>
              </div>
              {basket.length ? <MyButton onClick={() => setHide((pre) => !pre)}>{t("..buyurtmaqilish")}</MyButton> : null}
              {type.type === "click" ? (
                <div className='payment_card'>
                  <div className='payment_text'><span>{t("..Tqilish")}</span></div>
                  <table className='payment_tables'>
                    <tr>
                      <th>{t("..Заказ№")}</th>
                      <th><span>{type.order_id}</span></th>
                    </tr>
                    <tr>
                      <th>{t("..ism")}</th>
                      <th><span>{type.full_name}</span></th>
                    </tr>
                    <tr>
                      <th>{t("..Telefon")}</th>
                      <th><span>{type.phone}</span></th>
                    </tr>
                    <tr>
                      <th>{t("..Mahsulotlar")}</th>
                      <th><span>{type.productCounter}</span></th>
                    </tr>
                    <tr>
                      <th>{t("..price")}</th>
                      <th><span>{type.price.toLocaleString()} so'm</span></th>
                    </tr>
                    <tr>
                      <th>{t("..turii")}</th>
                      <th><span>{type.type}</span></th>
                    </tr>
                    <tr>
                      <th>{t("..To'lov holati")}</th>
                      <th><span style={{ color: 'red' }}>{t("..Оплаченный")}</span></th>
                    </tr>
                    <tr>
                      <th>{t("..To'lov holati")}</th>
                      <th><span style={{ color: 'green' }}>{t("..выплачивается")}</span></th>
                    </tr>
                  </table>
                  <div className='tolov'>
                    <form className='click_payment' action="https://my.click.uz/services/pay" id="click_form" method="get" target="_blank">
                      <input type="hidden" name="amount" value={type.price} />
                      <input type="hidden" name="merchant_id" value="14929" />
                      <input type="hidden" name="merchant_user_id" value="23877" />
                      <input type="hidden" name="service_id" value="20603" />
                      <input type="hidden" name="transaction_param" value={type.order_id} />
                      {/* <input type="hidden" name="return_url" value="" /> */}
                      <button type="submit" class="click_logo"><i></i><img src={Click} alt="click" /></button>
                    </form>
                  </div>
                  <button type="button" class="close btn btn-danger" data-bs-toggle="modal" data-bs-target="#exampleModal">
                  {t("..Отменить")}
                  </button>
                  <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5 class="modal-title" id="exampleModalLabel">{t("..хотите")}</h5>
                        </div>
                        <div class="modal-footer">
                          <button onClick={()=> dispatch(clearInfoProducts())} type="button" class="btn btn-success" data-bs-dismiss="modal">{t("..ha")}</button>
                          <button type="button" class="btn btn-danger" data-bs-dismiss="modal">{t("..yoq")}</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              {type.type === "processing" ? (
                <div className='payment_card'>
                  <div className='payment_text'><span>{t("..Tqilish")}</span></div>
                  <table className='payment_tables'>
                    <tr>
                      <th>{t("..ism")}</th>
                      <th><span>{type.full_name}</span></th>
                    </tr>
                    <tr>
                      <th>{t("..Telefon")}</th>
                      <th><span>{type.phone}</span></th>
                    </tr>
                    <tr>
                      <th>{t("..Mahsulotlar")}</th>
                      <th><span>{type.productCounter}</span></th>
                    </tr>
                    <tr>
                      <th>{t("..price")}</th>
                      <th><span>{type.price.toLocaleString()} so'm</span></th>
                    </tr>
                    <tr>
                      <th>{t("..turii")}</th>
                      <th><span>{type.type === "processing" ? t("..Naxt") : null}</span></th>
                    </tr>
                    <tr>
                      <th>{t("..To'lov holati")}</th>
                      <th><span style={{ color: 'red' }}>{t("..Оплаченный")}</span></th>
                    </tr>
                    <tr>
                      <th>{t("..To'lov holati")}</th>
                      <th><span style={{ color: 'green' }}>{t("..выплачивается")}</span></th>
                    </tr>
                  </table>
                  <span style={{ color: '#FF9900', textAlign: 'end' }}>{t("..naxtpulbilan")}</span>
                  <button type="button" class="close btn btn-danger" data-bs-toggle="modal" data-bs-target="#exampleModal">
                  {t("..Отменить")}
                  </button>
                  <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5 class="modal-title" id="exampleModalLabel">{t("..хотите")}</h5>
                        </div>
                        <div class="modal-footer">
                          <button onClick={()=> dispatch(clearInfoProducts())} type="button" class="btn btn-success" data-bs-dismiss="modal">{t("..ha")}</button>
                          <button type="button" class="btn btn-danger" data-bs-dismiss="modal">{t("..yoq")}</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </FadeIn>
    </div>
  )
}

export default Basket
