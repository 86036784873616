import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import './categories.css'
import FadeIn from 'react-fade-in'



const ProdctCategoty = ({ setCatModal }) => {
  const { t } = useTranslation();
  const lang = localStorage.getItem("i18nextLng")
  const Categories = useSelector((state) => state?.category?.category?.result)
  // console.log("Categoties",Categories)
  return (
    <FadeIn>
      <div className='categories'>
        <h3>{t("..kategoriyalar")}</h3>
        {Categories?.map((item) => {
          return (
            <a href={"/category/" + item.id} key={item.id} onClick={() => setCatModal(false)}><span>{lang === "uz" ? item.name_uz : item.name_ru}</span></a>
          )
        }).reverse()}
      </div>
    </FadeIn>
  )
}
export default ProdctCategoty