import React from "react";
import MakeFirma from '../components/makeinFirma/MakePc'
const MakePc = () => {
  return (
    <>
      <div className="section">
        <MakeFirma/>
      </div>
    </>
  )
}

export default MakePc;
