import React from 'react'
import logo from '../../images/_2686037395568footer_logotip.svg'
import './footer.css'
import facebook from '../../images/Group 91facebook_footer.svg'
import telegram from '../../images/Group 92telegram_Footer_Icon.svg'
import instagram from '../../images/Group 93Instagram_Footer_Icon.svg'
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();
  return (
      <div className="footer">
         <div className="container">
      <div className="row">
        <div className="col-lg-3 col-md-3 col-sm-6 left">
         <div className='log1'><img rel="preload" as="image" src={logo}  alt="icon_slider"  decoding="auto" /></div>
         <div className='logs'><div><img rel="preload" as="image" src={facebook}  alt="icon_slider"  decoding="auto" /><a href="https://www.facebook.com/SGT-101364089064598" target="_blank" rel='noreferrer'>Facebook</a></div></div>
         <div className='logs'><div><img rel="preload" as="image" src={telegram}  alt="icon_slider"  decoding="auto" /></div><a href="https://t.me/SGTUZB" target="_blank" rel='noreferrer'>Telegram</a></div>
         <div className='logs'><div><img rel="preload" as="image" src={instagram}  alt="icon_slider"  decoding="auto" /><a href="https://www.instagram.com/sgt_it/" target="_blank" rel='noreferrer'>Instagram</a></div></div>
        </div>
        <div className="col-lg-3 col-md-4 col-sm-6 left">
         <div className='log_span'><div><span>{t("..Malumot")}</span></div></div>
         <div className='logs_href'><div><a href="#товары">{t("..engyaxshipc")}</a></div></div>
         <div className='logs_href'><div><a href="#Ноутбуки">{t("..noutbuklar")}</a></div></div>
         <div className='logs_href'><div><a href="#qurilmalar">{t("..Aksessuarlar")}</a></div></div>
        </div>
        <div className="col-lg-6 col-md-5 col-sm-9 bottom">
          <span>{t("..xizmatlar1")} <br/> {t("..xizmatlar2")}</span>
          <a href="tel:+998935505555" target="_blank" rel='noreferrer'>+99893 550 55 55 </a>
          <a href="tel:+998977130094" target="_blank" rel='noreferrer'>+99897 713 00 94</a>
          <a href="https://t.me/sgt_robot" target="_blank" rel='noreferrer'>@sgt_it_bot</a>
        </div>
      </div>
      </div>
    </div>
  )
}

export default Footer
