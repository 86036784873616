import React, { useState } from "react";
import './product.css'
import MyButton from '../buttons//Button'
import { useTranslation } from 'react-i18next'
import { useParams } from "react-router-dom";
import Modal from '../modal_image/ImageModal'
import { useSelector, useDispatch } from 'react-redux'
import { addTodo } from '../../redux/Basketslice'
import { useEffect } from "react";
import axios from "axios";
import Correct from '../../images/check-circle-fill.svg'
import { ToastContainer, toast } from 'react-toastify';

const Product = () => {
  const notify = (items, letter) => toast.success(`${items.name} ${letter}`);
  const getProduct = (id, letter) => {
    notify(id, letter)
    dispatch(addTodo(id))
  }
  const lang = localStorage.getItem('i18nextLng')
  const [visible, setVisible] = useState(
    {
      hide: false,
      src: null
    }
  )
  const sendModalImage = (src) => {
    setVisible({ ...visible, hide: true, src: src })
  }

  const { t } = useTranslation()
  const pageId = useParams()
  const basket = useSelector((state) => state.basket.basket)
  const data1 = useSelector((state) => state.toolkit.data.result)
  const [description, setDescription] = useState([])
  const dispatch = useDispatch()

  const buyActive = basket.filter((item) => {
    if (item.id == pageId.id)
      return 1
    else {
      return 0
    }
  })
  useEffect(() => {
    const res = axios.get(`https://sgtback.pythonanywhere.com/api/v1/shop/description/?product=${pageId.id}`).then((res) => setDescription(res.data.result))
  }, [pageId])


  // Filter qilish  SON va STRING kelganda 
  const filters = data1?.filter((item) => {
    if (parseInt(pageId.id) >= 0) {
      return +item?.id == +pageId.id
    }
    else {
      return item.name.toLowerCase().includes(pageId.id.toLowerCase())
    }
  })

  return (
    <>
      <div className="product">
      <ToastContainer position="top-center"  style={{ minWidth: "350px", maxWidth:'600px'}} 
          autoClose={2000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover />
        <div className="container">
          {filters?.map((id) => {
            return (
              <div className="row" style={{ justifyContent: 'space-between' }} key={id.id}>
                <h1>{id.name}</h1>
                <div className="col-lg-8 col-md-12 product_left">
                  <div className="foto_product">
                    <div className="info_pc" >
                      <img onClick={() => sendModalImage(id.image1)} rel="preload" as="image" src={`https://sgtback.pythonanywhere.com` + id.image1} alt="icon_slider" decoding="auto" />
                    </div>
                    <div className="micro_foto">
                      <Modal visible={visible} setVisible={setVisible} />
                      {id.image2 === null ? null : <div className="micro_foto_dialog" onClick={() => sendModalImage(id.image2)}><img src={`https://sgtback.pythonanywhere.com` + id.image2} alt={id.imag2} /></div>}
                      {id.image3 === null ? null : <div className="micro_foto_dialog" onClick={() => sendModalImage(id.image3)}><img src={`https://sgtback.pythonanywhere.com` + id.image3} alt={id.imag3} /></div>}
                      {id.image4 === null ? null : <div className="micro_foto_dialog" onClick={() => sendModalImage(id.image4)}><img src={`https://sgtback.pythonanywhere.com` + id.image4} alt={id.imag4} /></div>}
                      {id.image5 === null ? null : <div className="micro_foto_dialog" onClick={() => sendModalImage(id.image5)}><img src={`https://sgtback.pythonanywhere.com` + id.image5} alt={id.imag5} /></div>}
                    </div>
                  </div>
                  <div className="product_info">
                    <span>{t("..Описание")}</span>
                    <p>{lang === "uz" ? id.description_uz : id.description_en}</p>
                    <div className='right_basket_bg'>
                    {/* <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', fontWeight:'600'}}>{t("..Mahsulot")}: <p style={{margin:'8px 0', color:'black'}}>{id.name}</p></div> */}
                    <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', fontWeight:'600'}}>{t("..price")}: <p style={{margin:'8px 0', color:'black'}}>{id.price_uzs.toLocaleString()} сум</p></div>
                    <MyButton onClick={() => getProduct(id,t("..qoshildi"))}>{buyActive.length ? (<>{t("..Товар")} <img style={{ marginLeft: '8px' }} src={Correct} alt="Correct" /></>) : t("..savatgasolish")}</MyButton>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 right">
                  <div className='right_basket_bg'>
                    {/* <div>{t("..Mahsulot")}: <p>{id.name}</p></div> */}
                    <div>{t("..price")}: <p>{id.price_uzs.toLocaleString()} сум</p></div>
                  </div>
                  <MyButton onClick={() => getProduct(id,t("..qoshildi"))}>{buyActive.length ? (<>{t("..Товар")} <img style={{ marginLeft: '8px' }} src={Correct} alt="Correct" /></>) : t("..savatgasolish")}</MyButton>
                </div>
                <div className="title_bottom">
                  <h6>{t("..Характеристики")} </h6>
                  {/* <span>Основные характеристики</span> */}
                </div>
                <div className="col-lg-12 info_bottom">
                  {description.map((items) => {
                    return (
                      <div key={items.id}><span>{items.key}</span><p>{items.value}</p></div>
                    )
                  })}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </>
  );
};

export default Product;
