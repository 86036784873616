import React, { useState } from "react";
import classes from './make.module.css'
import MyButton from '../../components/buttons/Button'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import cpu from '../../images/cpu.png'
import cpu_not from '../../images/cpu_not.png'
import not_case from '../../images/computer-tower.png'
import active_case from '../../images/computer-tower_active.png'
import ram from '../../images/ram.png'
import not_ram from '../../images/ram_not.png'
import videocard from '../../images/graphics-card.png'
import not_videocard from '../../images/graphics-card_not.png'
import star from '../../images/star.png'
import not_rom from '../../images/rom_not.png'
import rom from '../../images/rom.png'
import Powersupplies_foto from '../../images/power-supply.png'
import not_powersupplies_foto from '../../images/power-supply (1).png'
import mother from '../../images/motherboard.png'
import not_mother from '../../images/motherboard (1).png'
import {addComputerParts} from '../../redux/Basketslice'
import { useNavigate } from 'react-router-dom'

const MakePc = () => {
  
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const Makes = useSelector((state) => state.category.makes)
  const [processorPC, setProcessor] = useState([])
  const [ramPC, setRam] = useState([])
  const [romPC, setRom] = useState([])
  const [videocardPC, setVideocard] = useState([])
  const [casePC, setCase] = useState([])
  const [powersupplies, setPowersupplies] = useState([])
  const [motherboard, setMotherboard] = useState([])
  const merged = [...processorPC, ...ramPC, ...romPC, ...videocardPC, ...casePC, ...powersupplies, ...motherboard,];
  const ProductPrice = merged.filter((item) => item).reduce((accumulator, currentValue) => accumulator + currentValue.price_uzs, 0);
  const ProductLenght = merged.filter((item) => item)

 

  function protsessorValue(e) {
    if (e.target.name == "processor") {
      setProcessor([JSON.parse(e.target.value)])
    }
    else if (e.target.name == "ram") {
      setRam([JSON.parse(e.target.value)])
    }
    else if (e.target.name == "rom") {
      setRom([JSON.parse(e.target.value)])
    }
    else if (e.target.name == "videocard") {
      setVideocard([JSON.parse(e.target.value)])
    }
    else if (e.target.name == "case") {
      setCase([JSON.parse(e.target.value)])
    }
    else if (e.target.name == "powersupplies") {
      setPowersupplies([JSON.parse(e.target.value)])
    }
    else if (e.target.name == "motherboard") {
      setMotherboard([JSON.parse(e.target.value)])
    }
  }
  const submitcomputer=()=>{
    dispatch(addComputerParts(ProductLenght))
    navigate("/basket")
  }
  return (
    <div className={classes.make}>
      <div className="container">
        <div className={classes.info_foto}>
          <div className="row">
            <h4>{t("..konstruktor")}</h4>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <img src={star} alt="star" />
              <p>{t("..konstruktor1")}</p>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <img src={star} alt="star" />
              <p>{t("..konstruktor2")}</p>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <img src={star} alt="star" />
              <p>{t("..konstruktor3")}</p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className={`col-lg-4 ${classes.left}`} style={{ marginBottom: '40px', padding: '0 15px' }}>
            {/* Processor */}
            {Makes?.result?.processor.length ? (
              processorPC[0] ? (
                <div className={classes.make_left}>
                  <div className={classes.make_img}><a href="#processor"><img src={cpu} alt="processor" /></a></div>
                  <div>
                    <a href={`#processor`} className={classes.path_links}>
                      <p>{t("..Markaziy")}</p>
                      <span>{processorPC[0].name}</span>
                    </a>
                  </div>
                </div>
              ) : (
                <div className={classes.make_left}>
                  <div className={classes.make_img}><a href="#processor"><img src={cpu_not} alt="processor" /></a></div>
                  <div>
                    <a href={`#processor`} className={classes.path_links}>
                      <p>{t("..Markaziy")}</p>
                    </a>
                  </div>
                </div>
              )
            ) : null}
            {/* Ram */}
            {Makes?.result?.ram.length ? (
              ramPC[0] ? (
                <div className={classes.make_left}>
                  <div className={classes.make_img}><a href="#ram"><img src={ram} alt="ram" /></a></div>
                  <div>
                    <a href={`#ram`} className={classes.path_links}>
                      <p>{t("..ОЗУ")}</p>
                      <span>{ramPC[0].name}</span>
                    </a>
                  </div>
                </div>
              ) : (
                <div className={classes.make_left}>
                  <div className={classes.make_img}><a href="#ram"><img src={not_ram} alt="ram" /></a></div>
                  <div>
                    <a href={`#ram`} className={classes.path_links}>
                      <p>{t("..ОЗУ")}</p>
                    </a>
                  </div>
                </div>
              )
            ) : null}
            {/* Videocard */}
            {Makes?.result?.videocard.length ? (
              videocardPC[0] ? (
                <div className={classes.make_left}>
                  <div className={classes.make_img}><a href="#videocard"><img src={videocard} alt="videocard" /></a></div>
                  <div>
                    <a href={`#videocard`} className={classes.path_links}>
                      <p>{t("..Видеокарта")}</p>
                      <span>{videocardPC[0].name}</span>
                    </a>
                  </div>
                </div>
              ) : (
                <div className={classes.make_left}>
                  <div className={classes.make_img}><a href="#videocard"><img src={not_videocard} alt="videocard" /></a></div>
                  <div>
                    <a href={`#videocard`} className={classes.path_links}>
                      <p>{t("..Видеокарта")}</p>
                    </a>
                  </div>
                </div>
              )
            ) : null}
            {/* Case */}
            {Makes?.result?.case.length ? (
              casePC[0] ? (
                <div className={classes.make_left}>
                  <div className={classes.make_img}><a href="#case"><img src={active_case} alt="case" /></a></div>
                  <div>
                    <a href={`#case`} className={classes.path_links}>
                      <p>{t("..Case")}</p>
                      <span>{casePC[0].name}</span>
                    </a>
                  </div>
                </div>
              ) : (
                <div className={classes.make_left}>
                  <div className={classes.make_img}><a href="#case"><img src={not_case} alt="case" /></a></div>
                  <div>
                    <a href={`#case`} className={classes.path_links}>
                      <p>{t("..Case")}</p>
                    </a>
                  </div>
                </div>
              )
            ) : null}
            {/* Rom */}
            {Makes?.result?.rom.length ? (
              romPC[0] ? (
                <div className={classes.make_left}>
                  <div className={classes.make_img}><a href="#rom"><img src={rom} alt="rom" /></a></div>
                  <div>
                    <a href={`#rom`} className={classes.path_links}>
                      <p>{t("..Rom")}</p>
                      <span>{romPC[0].name}</span>
                    </a>
                  </div>
                </div>
              ) : (
                <div className={classes.make_left}>
                  <div className={classes.make_img}><a href="#rom"><img src={not_rom} alt="rom" /></a></div>
                  <div>
                    <a href={`#rom`} className={classes.path_links}>
                      <p>{t("..Rom")}</p>
                    </a>
                  </div>
                </div>
              )
            ) : null}
            {/* Powersupplies */}
            {Makes?.result?.powersupplies.length ? (
              powersupplies[0] ? (
                <div className={classes.make_left}>
                  <div className={classes.make_img}><a href="#powersupplies"><img src={not_powersupplies_foto} alt="powersupplies" /></a></div>
                  <div>
                    <a href={`#powersupplies`} className={classes.path_links}>
                      <p>{t("..Powersupplies")}</p>
                      <span>{powersupplies[0].name}</span>
                    </a>
                  </div>
                </div>
              ) : (
                <div className={classes.make_left}>
                  <div className={classes.make_img}><a href="#powersupplies"><img src={Powersupplies_foto} alt="rom" /></a></div>
                  <div>
                    <a href={`#powersupplies`} className={classes.path_links}>
                      <p>{t("..Powersupplies")}</p>
                    </a>
                  </div>
                </div>
              )
            ) : null}
            {/* Motherboard */}
            {Makes?.result?.motherboard.length ? (
              motherboard[0] ? (
                <div className={classes.make_left}>
                  <div className={classes.make_img}><a href="#motherboard"><img src={mother} alt="motherboard" /></a></div>
                  <div>
                    <a href={`#motherboard`} className={classes.path_links}>
                      <p>{t("..motherboard")}</p>
                      <span>{motherboard[0].name}</span>
                    </a>
                  </div>
                </div>
              ) : (
                <div className={classes.make_left}>
                  <div className={classes.make_img}><a href="#motherboard"><img src={not_mother} alt="motherboard" /></a></div>
                  <div>
                    <a href={`#motherboard`} className={classes.path_links}>
                      <p>{t("..motherboard")}</p>
                    </a>
                  </div>
                </div>
              )
            ) : null}
          </div>

          {/* Readdd ***************************************************/}
          <div className="col-lg-5 center" style={{ padding: '0 10px' }}>
            {/* Processor */}
            {Makes?.result?.processor.length ? (
              <div className={classes.protsessor}>
                <div className={classes.result_info}>
                  <div className={classes.info_arays}><span>{t("..Markaziy")}</span></div>
                  <select onChange={protsessorValue} name="processor" id="processor">
                    <option value={false} selected>{t("..tanlov")}</option>
                    <option disabled>{t("..Markaziy")}</option>
                    {Makes?.result?.processor.map((processor) => {
                      return (
                        <option value={JSON.stringify(processor)}>{processor?.name}</option>
                      )
                    })}
                  </select>
                </div>
                {processorPC[0] ? (
                  <div className={classes.info_arrays}>
                    <div><img src={`https://sgtback.pythonanywhere.com` + processorPC[0].image1} alt="mikrofon" /></div>
                    <div className={classes.info_price}><p>{processorPC[0].name}</p> <span>{processorPC[0].price_uzs.toLocaleString()} so'm</span> </div>
                  </div>
                ) : null}
              </div>
            ) : null}
            {/* Ram */}
            {Makes?.result?.ram.length ? (
              <div className={classes.protsessor}>
                <div className={classes.result_info}>
                  <div className={classes.info_arays}><span>{t("..ОЗУ")}</span></div>
                  <select onChange={protsessorValue} name="ram" id="ram">
                    <option value={false} selected>{t("..tanlov")}</option>
                    <option disabled>{t("..ОЗУ")}</option>
                    {Makes?.result?.ram.map((processor) => {
                      return (
                        <option value={JSON.stringify(processor)}>{processor?.name}</option>
                      )
                    })}
                  </select>
                </div>
                {ramPC[0] ? (
                  <div className={classes.info_arrays}>
                    <div><img src={`https://sgtback.pythonanywhere.com` + ramPC[0].image1} alt="mikrofon" /></div>
                    <div className={classes.info_price}><p>{ramPC[0].name}</p> <span>{ramPC[0].price_uzs.toLocaleString()} so'm</span></div>
                  </div>
                ) : null}
              </div>
            ) : null}
            {/* ROM use */}
            {Makes?.result?.rom.length ? (
              <div className={classes.protsessor}>
                <div className={classes.result_info}>
                  <div className={classes.info_arays}><span>{t("..Rom")}</span></div>
                  <select onChange={protsessorValue} name="rom" id="rom">
                    <option value={false} selected>{t("..tanlov")}</option>
                    <option disabled>{t("..Rom")}</option>
                    {Makes?.result?.rom.map((processor) => {
                      return (
                        <option value={JSON.stringify(processor)}>{processor.name}</option>
                      )
                    })}
                  </select>
                </div>
                {romPC[0] ? (
                  <div className={classes.info_arrays}>
                    <div><img src={`https://sgtback.pythonanywhere.com` + romPC[0].image1} alt="rom" /></div>
                    <div className={classes.info_price}><p>{romPC[0].name}</p> <span>{romPC[0].price_uzs.toLocaleString()} so'm</span></div>
                  </div>
                ) : null}
              </div>
            ) : null}
            {/* Videocard */}
            {Makes?.result?.videocard.length ? (
              <div className={classes.protsessor}>
                <div className={classes.result_info}>
                  <div className={classes.info_arays}><span>{t("..Видеокарта")}</span></div>
                  <select onChange={protsessorValue} name="videocard" id="videocard">
                    <option value={false} selected>{t("..tanlov")}</option>
                    <option disabled>{t("..Видеокарта")}</option>
                    {Makes?.result?.videocard.map((processor) => {
                      return (
                        <option value={JSON.stringify(processor)}>{processor.name}</option>
                      )
                    })}
                  </select>
                </div>
                {videocardPC[0] ? (
                  <div className={classes.info_arrays}>
                    <div><img src={`https://sgtback.pythonanywhere.com` + videocardPC[0].image1} alt="videocard" /></div>
                    <div className={classes.info_price}><p>{videocardPC[0].name}</p> <span>{videocardPC[0].price_uzs.toLocaleString()} so'm</span></div>
                  </div>
                ) : null}
              </div>
            ) : null}
            {/* Case */}
            {Makes?.result?.case.length ? (
              <div className={classes.protsessor}>
                <div className={classes.result_info}>
                  <div className={classes.info_arays}><span>{t("..Case")}</span></div>
                  <select onChange={protsessorValue} name="case" id="case">
                    <option value={false} selected>{t("..tanlov")}</option>
                    <option disabled>{t("..Case")}</option>
                    {Makes?.result?.case.map((processor) => {
                      return (
                        <option value={JSON.stringify(processor)}>{processor.name}</option>
                      )
                    })}
                  </select>
                </div>
                {casePC[0] ? (
                  <div className={classes.info_arrays}>
                    <div><img src={`https://sgtback.pythonanywhere.com` + casePC[0].image1} alt="mikrofon" /></div>
                    <div className={classes.info_price}><p>{casePC[0].name}</p> <span>{casePC[0].price_uzs.toLocaleString()} so'm</span></div>
                  </div>
                ) : null}
              </div>
            ) : null}
            {/* Powersupplies */}
            {Makes?.result?.powersupplies.length ? (
              <div className={classes.protsessor}>
                <div className={classes.result_info}>
                  <div className={classes.info_arays}><span>{t("..Powersupplies")}</span></div>
                  <select onChange={protsessorValue} name="powersupplies" id="powersupplies">
                    <option value={false} selected>{t("..tanlov")}</option>
                    <option disabled>{t("..Powersupplies")}</option>
                    {Makes?.result?.powersupplies.map((processor) => {
                      return (
                        <option value={JSON.stringify(processor)}>{processor.name}</option>
                      )
                    })}
                  </select>
                </div>
                {powersupplies[0] ? (
                  <div className={classes.info_arrays}>
                    <div><img src={`https://sgtback.pythonanywhere.com` + powersupplies[0].image1} alt="powersupplies" /></div>
                    <div className={classes.info_price}><p>{powersupplies[0].name}</p> <span>{powersupplies[0].price_uzs.toLocaleString()} so'm</span></div>
                  </div>
                ) : null}
              </div>
            ) : null}
            {/* Motherboard */}
            {Makes?.result?.motherboard.length ? (
              <div className={classes.protsessor}>
                <div className={classes.result_info}>
                  <div className={classes.info_arays}><span>{t("..motherboard")}</span></div>
                  <select onChange={protsessorValue} name="motherboard" id="motherboard">
                    <option value={false} selected>{t("..tanlov")}</option>
                    <option disabled>{t("..motherboard")}</option>
                    {Makes?.result?.motherboard.map((processor) => {
                      return (
                        <option value={JSON.stringify(processor)}>{processor.name}</option>
                      )
                    })}
                  </select>
                </div>
                {motherboard[0] ? (
                  <div className={classes.info_arrays}>
                    <div><img src={`https://sgtback.pythonanywhere.com` + motherboard[0].image1} alt="motherboard" /></div>
                    <div className={classes.info_price}><p>{motherboard[0].name}</p> <span>{motherboard[0].price_uzs.toLocaleString()} so'm</span></div>
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>
          <div className="col-lg-3" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', }}>
            <div className={classes.basket_top}>
              <h6>{t("..zakaznarxi")}</h6>
              <div><span>{t("..tovar")}:</span><p>{ProductLenght.length}</p></div>
              <div><span>{t("..price")}:</span><p>{ProductPrice.toLocaleString()}</p></div>
            </div>
            <div className={classes.btn_oformit}>
            <MyButton onClick={()=> submitcomputer()}>{t("..buyurtmaqilish")}</MyButton>

              {/* <MyButton style={{background:'transparent', border:'1px solid #FF9900'}}>{t("..savatgasolish")}</MyButton> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MakePc;
