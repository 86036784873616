import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import clasess from './cards.module.css'
import buybox from '../../images/VectorCardbuybox.svg'
import MyButton from '../buttons/Button'
import Slider from "react-slick";
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import icon_slider from '../../images/Unionstrelka_slider.svg'
import { useSelector, useDispatch } from 'react-redux'
import { addTodo } from '../../redux/Basketslice'
import { addLikes } from '../../redux/GetSlice'
import Correct from '../../images/check-circle-fill.svg'
import { ToastContainer, toast } from 'react-toastify';
import Loadings from '../loader/Loader'


const Cards = () => {
  const { t } = useTranslation();
  const notify = (items, letter) => toast.success(`${items.name} ${letter}`);
  const { status, error, data } = useSelector((state) => state.toolkit)
  const dispatch = useDispatch()
  const addCards = (items, letter) => {
    notify(items, letter)
    dispatch(addTodo(items))
    dispatch(addLikes(items.id))
  }
  const settings = {
    autoplay: false,
    pauseOnHover: true,
    pauseOnFocus: true,
    swipeToSlide: true,
    adaptiveHeight: true,
    speed: 300,
    autoplaySpeed: 10000,
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 786,
        settings: {
          slidesToShow: 3,
          initialSlide: 2
        }
      },
      {
        breakpoint: 577,
        settings: {
          slidesToShow: 2,
          arrows: false,
        }
      }
    ]
  };

if(status === 'loading'){
  return <div className='container'><Loadings/></div>
}
if(error){
  return  <div className='container' style={{textAlign:'center'}}><h1 style={{color:'#f90', fontSize:'24px', margin:'20px'}}>Системная ошибка, Пожалуйста попробуйте еще раз</h1></div>
}

else{
  return (
    <div className="container">
      <ToastContainer position="top-center" style={{ minWidth: "350px", maxWidth: '600px' }}
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />

      {data?.result?.filter((prod) => prod.is_top).length ? (
        <div className="row" style={{ justifyContent: 'space-around', marginBottom: '40px' }}>
          <div className={clasess.title}><span id="товары">{t("..engyaxshipc")}</span><Link to={"/category/top"} style={{ textDecoration: 'none' }}><p>{t("..смотретьвсе")} <img src={icon_slider} alt="icon_slider" /></p></Link></div>
          <Slider {...settings}>
            {data?.result?.filter((prod) => prod.is_top)?.map((item) => {
              return (
                <div className={clasess.card_bg} key={item.id}>
                  <div className={clasess.new_card}>
                    <>
                      <Link to={`/product/` + item?.id}>
                        <div className={clasess.card_img}><img rel="preload" effect="blur" as="image" src={`https://sgtback.pythonanywhere.com` + item.image1} alt="fotos" decoding="auto" /></div>
                      </Link>
                    </>
                    <div>
                     <Link to={`/product/` + item?.id}>
                       <div className={clasess.card_title}>{item.name}</div>
                     </Link>
                     <div className={clasess.card_price}>
                       <div><span>{Math.round(item?.price_uzs).toLocaleString()} so'm</span></div>
                       <div><img onClick={() => addCards(item, t("..qoshildi"))} src={item.likes ? Correct : buybox} alt="buybox" /></div>
                     </div>
                     <div className={clasess.card_button}> <Link to={`/product/` + item?.id} style={{ textDecoration: 'none', width: '100%' }}>
                       <MyButton>{t("..buybutton")}</MyButton>
                     </Link></div>
                    </div>
                  </div>
                </div>
              )
            })}
          </Slider>
        </div>
      ) : null}
      {data?.result?.filter((prod) => prod.category === 10).length ? (
        <div className="row" style={{ justifyContent: 'space-around', marginBottom: '40px' }}>
          <div className={clasess.title}><span id="Ноутбуки">{t("..noutbuklar")}</span> <Link to={"/category/" + 10} style={{ textDecoration: 'none' }}><p>{t("..смотретьвсе")} <img rel="preload" as="image" src={icon_slider} alt="icon_slider" decoding="auto" /></p></Link></div>
          <Slider {...settings}>
            {data?.result?.filter((prod) => prod.category === 10).map((item) => {
              return (
                <div className={clasess.card_bg} key={item.id}>
                  <div className={clasess.new_card}>
                    <><Link to={`/product/` + item?.id}>
                      <div className={clasess.card_img}><img rel="preload" effect="blur" as="image" src={`https://sgtback.pythonanywhere.com` + item.image1} alt="fotos" decoding="auto" /></div>
                    </Link>
                    </>
                    <div>
                    <Link to={`/product/` + item?.id}>
                      <div className={clasess.card_title}>{item.name}</div>
                    </Link>
                    <div className={clasess.card_price}>
                      <div><span>{Math.round(item?.price_uzs).toLocaleString()} so'm</span></div>
                      <div><img onClick={() => addCards(item, t("..qoshildi"))} src={item.likes ? Correct : buybox} alt="buybox" /></div>
                    </div>
                    <div className={clasess.card_button}> <Link to={`/product/` + item?.id} style={{ textDecoration: 'none', width: '100%' }}>
                      <MyButton>{t("..buybutton")}</MyButton>
                    </Link></div>
                    </div>
                    </div>
                </div>
              )
            })}
          </Slider>
        </div>
      ) : null}
      {data?.result?.filter((prod) => prod.category === 4).length ? (
        <div className="row" style={{ justifyContent: 'space-around', marginBottom: '40px' }}>
          <div className={clasess.title}><span>{t("..Markaziy")}</span> <Link to={"/category/"+4} style={{ textDecoration: 'none' }}><p>{t("..смотретьвсе")} <img src={icon_slider} alt="icon_slider" /></p></Link></div>
          <Slider {...settings}>
            {data?.result?.filter((prod) => prod.category === 4).map((item) => {
              return (
                 <div className={clasess.card_bg} key={item.id}>
                  <div className={clasess.new_card}>
                    <><Link to={`/product/` + item?.id}>
                      <div className={clasess.card_img}><img rel="preload" effect="blur" as="image" src={`https://sgtback.pythonanywhere.com` + item.image1} alt="fotos" decoding="auto" /></div>
                    </Link></>
                    <div>
                    <Link to={`/product/` + item?.id}>
                      <div className={clasess.card_title}>{item.name}</div>
                    </Link>
                    <div className={clasess.card_price}>
                      <div><span>{Math.round(item?.price_uzs).toLocaleString()} so'm</span></div>
                      <div><img onClick={() => addCards(item, t("..qoshildi"))} src={item.likes ? Correct : buybox} alt="buybox" /></div>
                    </div>
                    <div className={clasess.card_button}> <Link to={`/product/` + item?.id} style={{ textDecoration: 'none', width: '100%' }}>
                      <MyButton>{t("..buybutton")}</MyButton>
                    </Link></div>
                    </div>
                    </div>
                </div>
              )
            })}
          </Slider>
        </div>
      ) : null}

      {data?.result?.filter((prod) => prod.category === 5).length ? (
        <div className="row" style={{ justifyContent: 'space-around', marginBottom: '40px' }}>
          <div className={clasess.title}><span>{t("..ОЗУ")}</span> <Link to={"/category/" + 5} style={{ textDecoration: 'none' }}><p>{t("..смотретьвсе")} <img rel="preload" as="image" src={icon_slider} alt="icon_slider" decoding="auto" /></p></Link></div>
          <Slider {...settings}>
            {data?.result?.filter((prod) => prod.category === 5).map((item) => {
              return (
                <div className={clasess.card_bg} key={item.id}>
                  <div className={clasess.new_card}>
                    <><Link to={`/product/` + item?.id}>
                      <div className={clasess.card_img}><img rel="preload" effect="blur" as="image" src={`https://sgtback.pythonanywhere.com` + item.image1} alt="fotos" decoding="auto" /></div>
                    </Link>
                    </>
                    <div>
                    <Link to={`/product/` + item?.id}>
                      <div className={clasess.card_title}>{item.name}</div>
                    </Link>
                    <div className={clasess.card_price}>
                      <div><span>{Math.round(item?.price_uzs).toLocaleString()} so'm</span></div>
                      <div><img onClick={() => addCards(item, t("..qoshildi"))} src={item.likes ? Correct : buybox} alt="buybox" /></div>
                    </div>
                    <div className={clasess.card_button}> <Link to={`/product/` + item?.id} style={{ textDecoration: 'none', width: '100%' }}>
                      <MyButton>{t("..buybutton")}</MyButton>
                    </Link></div>
                    </div>
                    </div>
                </div>
              )
            })}
          </Slider>
        </div>
      ) : null}
      {data?.result?.filter((prod) => prod.is_accessory).length ? (
        <div className="row" style={{ justifyContent: 'space-around', marginBottom: '40px' }}>
          <div className={clasess.title}><span id='qurilmalar'>{t("..qurilmalar")}</span> <Link to={"/category/acsessuar"} style={{ textDecoration: 'none' }}><p>{t("..смотретьвсе")} <img src={icon_slider} alt="icon_slider" /></p></Link></div>
          <Slider {...settings}>
            {data?.result?.filter((prod) => prod.is_accessory).map((item) => {
              return (
                 <div className={clasess.card_bg} key={item.id}>
                  <div className={clasess.new_card}>
                    <><Link to={`/product/` + item?.id}>
                      <div className={clasess.card_img}><img rel="preload" effect="blur" as="image" src={`https://sgtback.pythonanywhere.com` + item.image1} alt="fotos" decoding="auto" /></div>
                    </Link></>
                    <div>
                    <Link to={`/product/` + item?.id}>
                      <div className={clasess.card_title}>{item.name}</div>
                    </Link>
                    <div className={clasess.card_price}>
                      <div><span>{Math.round(item?.price_uzs).toLocaleString()} so'm</span></div>
                      <div><img onClick={() => addCards(item, t("..qoshildi"))} src={item.likes ? Correct : buybox} alt="buybox" /></div>
                    </div>
                    <div className={clasess.card_button}> <Link to={`/product/` + item?.id} style={{ textDecoration: 'none', width: '100%' }}>
                      <MyButton>{t("..buybutton")}</MyButton>
                    </Link></div>
                    </div>
                    </div>
                </div>
              )
            })}
          </Slider>
        </div>
      ) : null}
    </div>
  )
}
  
}

export default Cards