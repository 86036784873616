import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios'

export const fetchTodos = createAsyncThunk(
  'todos/fetchTodos',

  async (_, { rejectWithValue }) => {
    try {
      const res = await axios.get(`https://sgtback.pythonanywhere.com/api/v1/shop/product/`);
      return res.data;

    } catch (error) {
      return rejectWithValue(error.message);
    }

  }
)

const setError = (state, action) => {
  state.status = 'failed';
  state.error = action.error.message;
}


const toolkitSlice = createSlice({
  name: "toolkit",
  initialState: {
    data: [],
    category: [],
    loading: null,
    error: null
  },
  reducers: {
    addLikes(state, action) {
      const addcount = state.data.result.find((todo => todo.id === action.payload))
      addcount.likes = +1
    },
    removeLikes(state, action) {
      const addcount = state.data.result.find((todo => todo.id === action.payload))
      addcount.likes = 0
    }
  },

  extraReducers: {
    [fetchTodos.pending]: (state) => {
      state.status = 'loading';
      state.error = null;
    },

    [fetchTodos.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
    },
    [fetchTodos.rejected]: setError,
  }

});

export default toolkitSlice.reducer;
export const { getFetch, basketPush, addLikes, removeLikes } = toolkitSlice.actions;
