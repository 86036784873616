import React, { useState } from 'react'
import './payment.css'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import MyButton from '../buttons/Button'
import axios from 'axios'
import { PriceCounter, CleaerBasket } from '../../redux/Basketslice'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom';



const Payment = ({ sum, productCounter }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const basket = useSelector((state) => state.basket.basket)
  const [checkvalues, setCheckValues] = useState({ payment: "", })
  const [adress, setAdrss] = useState({
    city: "",
    adress: "",
    country: "",
    full_name: "",
    phone: "",
  })

  function protsessorValue(e) {
    if (e.target.name === "payment") {
      setCheckValues({ ...checkvalues, payment: e.target.value })
    }
  }
  const SubmitPost = async (e) => {
    e.preventDefault()
    const base_url = "https://sgtback.pythonanywhere.com"
    try {
      const ordeItemsIds = [];
      for (let i = 0; i < basket.length; i++) {
         await axios.post(`${base_url}/api/v1/shop/orderItem/`, {
          product: basket[i].id,
          quantities: basket[i].count
        })
          .then((res) => ordeItemsIds.push(res.data.result.id))
      }
      await axios.post("https://sgtback.pythonanywhere.com/api/v1/shop/order/",
        {
          full_name: adress.full_name,
          orderItems: ordeItemsIds,
          phone: adress.phone,
          address: adress.adress,
          city: adress.city,
          country: adress.country,
          pay: checkvalues.payment,
          status: "Pending",
          zipcode: "1"
        })
        .then((res) =>
          dispatch(PriceCounter({ order_id: res.data.result.order_id, productCounter: productCounter, full_name: adress.full_name, phone: adress.phone, price: sum, type: checkvalues.payment, })),
          setAdrss({ ...adress, full_name: "", adress: "", phone: "", city: "", country: "" }),
          setCheckValues({ ...checkvalues, payment: "" })).then((res) => dispatch(CleaerBasket()))
      navigate("/")
      alert("Ваш заказ принят")
    } catch (error) {
      alert("Пожалуйста, попробуйте еще раз, системная ошибка!", error)
    }
  }
  return (
    <>
      <div className='col-lg-9 bask_check'>
        {basket.length ? (
           <form onSubmit={SubmitPost}>
           <div className="adres">
             <h4>{t("..ismingizFIO")}</h4>
             <div>
               <input required type="={true}text" placeholder={t("..ismingizFIO")} style={{ width: '100%' }} value={adress.full_name} onChange={(e) => setAdrss({ ...adress, full_name: e.target.value })} />
             </div>
             <h4>{t("..teL")}</h4>
             <div>
               <input required={true} placeholder={t("..teL")} type="tel" style={{ width: '100%' }} value={adress.phone} onChange={(e) => setAdrss({ ...adress, phone: e.target.value })} />
             </div>
           </div>
           <h4 style={{ marginTop: "20px" }}>{t("..usulnitanlash")}</h4>
           <div class="form-check">
             <input required={true} onChange={protsessorValue} value={"processing"} class="form-check-input" type="radio" name="payment" id="fl1" />
             <label class="form-check-label" for="fl1" style={checkvalues.payment == "processing" ? { color: '#FF9900' } : { color: '#E0E0E0' }}>
               {t("..naqdpul")}
             </label>
           </div>
           <div class="form-check">
             <input required={true} onChange={protsessorValue} value={"click"} class="form-check-input" type="radio" name="payment" id="fl2" />
             <label class="form-check-label" for="fl2" style={checkvalues.payment == "click" ? { color: '#FF9900' } : { color: '#E0E0E0' }}>
               {t("..click")}
             </label>
           </div>
           {/* <div class="form-check">
             <input required={true} onChange={protsessorValue} value={"payme"} class="form-check-input" type="radio" name="payment" id="fl3" />
             <label class="form-check-label" for="fl3" style={checkvalues.payment == "payme" ? { color: '#FF9900' } : { color: '#E0E0E0' }}>
               {t("..payme")}
             </label>
           </div> */}
           <div className="adres">
             <h4>{t("..olishusuli")}</h4>
             <span>{t("..yetkazibberish")}</span>
             <div style={{ display: 'flex', justifyContent: 'space-between' }}>
               <input required={true} type="text" placeholder={t("..shahar")} value={adress.city} onChange={(e) => setAdrss({ ...adress, city: e.target.value })} />
               <input required={true} type="text" placeholder={t("..viloyat")} value={adress.adress} onChange={(e) => setAdrss({ ...adress, adress: e.target.value })} />
             </div>
             <div>
               <input required={true} type="text" placeholder={t("..malutot")} style={{ width: '100%' }} value={adress.country} onChange={(e) => setAdrss({ ...adress, country: e.target.value })} />
             </div>
           </div>
           <MyButton style={{ width: '100%', justifyContent: "center", marginTop: "5px" }}> {t("..Sotibolish")}</MyButton>
         </form>
        ) : null}
      </div>
    </>
  )
}

export default Payment