import "./App.css";
import React, {useEffect} from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from './pages/Home';
import Basket from './pages/Basket'
import Product from './pages/ProductID'
import Navbar from './components/navbar/Navbar'
import MakePc from './pages/MakePc'
import MakeFirma from './pages/MakeFermu'
import ScrollToTop from "./ScrollTop";
import Category from './pages/Category.js'
import {useDispatch} from 'react-redux'
import {fetchTodos} from './redux/GetSlice'
import {CategoryFetch,BrandFetch,MakeFetch,Minefarm} from './redux/Category'
import Footer from './components/footer/Footer'
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";


function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchTodos());
    dispatch(CategoryFetch());
    dispatch(BrandFetch());
    dispatch(MakeFetch());
    dispatch(Minefarm());
  }, [dispatch]);

  
  return (
    <>
     <Router basename="/">
      <ScrollToTop/>
      <Navbar/>
      <Routes>
        <Route exact={true} path="/" element={<Home/>}/>
        <Route exact={true} path="/*" element={<Home/>}/>
        <Route exact={true} path="/basket" element={<Basket/>}/>
        <Route exact={true} path="/category/:id" element={<Category/>}/>
        <Route exact={true} path="/makepc" element={<MakePc/>}/>
        <Route exact={true} path="/makefirma" element={<MakeFirma/>}/>
        <Route exact={true} path="/product/:id" element={<Product/>}/>
      </Routes>
      <div className="section">
        <Footer/>
      </div>
    </Router> 
    </>
  );
}

export default App;
