import React from 'react'
import cl from './loader.module.css'

const Loader = () => {
  return (
    <div style={{display:'flex', justifyContent:'center', marginTop:'15px', width:'100%', margin:'100px auto'}}>
      <div className={cl.loader}>
      </div>
    </div>
  )
}

export default Loader