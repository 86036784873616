import React from "react";
import Product from '../components/productID/Product'

const ProductID = () => {

  return (
    <>
    <div className="section">
      <Product/>
    </div>
    </>
  )

}

export default ProductID
