import { createSlice } from "@reduxjs/toolkit";

const BasketShop = createSlice({
  name: "BasketShop",
  initialState: {
    basket: [],
    makecomputer:[],
    InfoProducts:[],
  },
  reducers: {
    PriceCounter(state, action){
      state.InfoProducts = action.payload
    },
    clearInfoProducts(state, action){
      state.InfoProducts = []
    },
    CleaerBasket(state, action){
      state.basket = []
    },
    addTodo(state, action) {
      if (!state.basket.length) {
        state.basket.push({
          ...action.payload,count:1,CounterPrice:action.payload.price_uzs
        });
      }
      if (!state.basket.find((basket) => basket.id === action.payload.id)) {
        state.basket.push({...action.payload,count:1,CounterPrice:action.payload.price_uzs});
      }
    },
    addComputerParts(state, action){
      action.payload.forEach((item)=>{ state.basket.push({...item,count:1,CounterPrice:item.price_uzs})})
    },
    removeBasketProduct(state, action) {
      state.basket = state.basket.filter((todo) => todo.id !== action.payload);
    },
    IncrementProduct(state, action){
      const addcount = state.basket.find((todo => todo.id === action.payload.id))
      addcount.count = addcount.count+1
      addcount.CounterPrice = addcount.CounterPrice + action.payload.price_uzs
    },
    DecrementProduct(state, action){
      const addcount = state.basket.find((todo => todo.id === action.payload.id))
      if(addcount.count >0){
        addcount.count = addcount.count-1
        addcount.CounterPrice = addcount.CounterPrice - addcount.price_uzs
         if(addcount.count === 0){
          state.basket = state.basket.filter((todo) => todo.id !== action.payload.id);
        }
      }
    }
  },
});

export const { clearInfoProducts, addTodo,PaymentType,CleaerBasket, removeBasketProduct, IncrementProduct, DecrementProduct,PaymentToggle,PriceCounter,addComputerParts } = BasketShop.actions;
export default BasketShop.reducer;
