import React from "react";
import './map.css'
const Map = () => {
  return (
      <div style={{ height: '450px' }}>
        <iframe
          title="map"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11977.43041700868!2d69.2952984!3d41.3663147!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x81cbb07a69691246!2sSmart%20Technology!5e0!3m2!1sru!2s!4v1642687895935!5m2!1sru!2s"
          width="100%"
          height="100%"
          allowFullScreen=""
          loading="lazy"
        ></iframe>
      </div>
  );
};
export default Map;
