import React from "react";
import MakePart from '../components/makepcParts/MakePc'
const MakePc = () => {
  return (
    <>
      <div className="section">
        <MakePart/>
      </div>
    </>
  )
}

export default MakePc;
