import React from 'react'
import cl from './modalImage.module.css'

const MyModal = ({children, visible, setVisible}) => {
  
  const rootClasses = [cl.myModal]

  if(visible.hide){
    rootClasses.push(cl.active)
  }

  const removeModal =()=>{
    setVisible([{...visible, hide:false}])
  }

  return (
    <div className={rootClasses.join(' ')} onClick={removeModal}>
      <div className={cl.modalContent} onClick={e => e.stopPropagation()}>
      <img src={`https://sgtback.pythonanywhere.com`+visible.src} alt="first" />
      </div>
    </div>
  )
}
// CI='' npm run build
export default MyModal