import React, { useState, useEffect } from 'react';
import './category.css'
import buybox from '../../images/VectorCardbuybox.svg'
import MyButton from '../buttons/Button'
import { useSelector, useDispatch } from 'react-redux'
import { addTodo } from '../../redux/Basketslice'
import Pagination from "../pagination/Pagination";
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import axios from 'axios';
import { Slider } from 'antd';
import { addLikes } from '../../redux/GetSlice'
import Correct from '../../images/check-circle-fill.svg'
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';
import shopping_cart from '../../images/danger.png'
import Loader from '../../components/loader/Loader'

const Category = () => {

  const notify = (items, letter) => toast.success(`${items.name} ${letter}`);
  const { t } = useTranslation();
  const ParamsId = useParams()

  const dispatch = useDispatch()
  const { status, error, data } = useSelector((state) => state.toolkit)
  const Categories = useSelector((state) => state?.category?.category?.result)
  const Brand = useSelector((state) => state.category?.brand?.result)

  const addCards = (items, letter) => {
    notify(items, letter)
    dispatch(addTodo(items))
    dispatch(addLikes(items.id))
  }
  const [todos, setTodos] = useState([])
  const [category, setCategory] = useState(ParamsId.id)
  const [brands, setBrands] = useState(null)

  const max = Math.max.apply(null, data?.result?.map(item => item.price));
  const min = Math.min.apply(null, data?.result?.map(item => item.price));

  const [range, setRange] = useState([0, 100])
  const mid = (max - min) / 100
  const minV = range?.[0] * mid + min
  const maxV = (range?.[1] * mid) + min

  const lang = localStorage.getItem("i18nextLng")
  useEffect(() => {
    const res = axios.get(`https://sgtback.pythonanywhere.com/api/v1/shop/product/?price_min=${minV}&is_top=${ParamsId.id == "top" ? true : null}&is_accessory=${ParamsId.id == "acsessuar" ? true : null}&price_max=${maxV}&category=${category}&brand=${brands}&name=${ParamsId.id == "top" ? '' : '' || ParamsId.id == "acsessuar" ? '' : '' || ParamsId.id == 1 ? '' : '' || ParamsId.id == 2 ? '' : '' || parseInt(ParamsId.id) >= 0 ? '' : ParamsId.id}`).then((res) => setTodos(res.data.result))
  }, [category, brands, ParamsId.id, range, minV, maxV])

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(6);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = todos?.slice(indexOfFirstPost, indexOfLastPost);
  const howManyPages = Math.ceil(todos?.length / postsPerPage);

  return (
    <>
      <div className="categor_pc">
        <ToastContainer position="top-center" style={{ minWidth: "350px", maxWidth: '600px' }}
          autoClose={2000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover />
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-3 left">
              <div className='top_categories'>
                <select name='category' value={category} onChange={(e) => setCategory(e.target.value)} class="form-select" aria-label="Default select example">
                  <option value="" selected>Categories</option>
                  {Categories?.map((item) => {
                    return <option key={item.id} value={item.id}>{lang === "uz" ? item.name_uz : item.name_ru}</option>
                  })}
                </select>
                <select name='brand' value={brands} onChange={(e) => setBrands(e.target.value)} class="form-select" aria-label="Default select example">
                  <option value="" selected>Brands</option>
                  {Brand?.map((item) => {
                    return <option key={item.id} value={item.id}>{lang === "uz" ? item.name_uz : item.name_ru}</option>
                  })}
                </select>
              </div>
              <div className='counter_bottom'>
                <div className='cat_counter'>
                  <div><span>от</span><p>{Math.round(minV).toLocaleString()} $</p></div>
                  <div><span>до</span><p>{Math.round(maxV).toLocaleString()} $</p></div>
                </div>
                <div className="category_ranges">
                  <Slider
                    range={{ draggableTrack: false }}
                    defaultValue={[0, 100]}
                    tooltipPlacement="None"
                    onChange={(e) => setRange(e)}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-md-9 col-sm-12 rights">
              {status === 'loading' ? <Loader/> : null || error ? <h1 className='category_error'>Системная ошибка, Пожалуйста попробуйте еще раз</h1>
               : currentPosts.length ? (
                  currentPosts?.map((item) => {
                    return (
                      <div className="cards" key={item.id}>
                        <div className="img_category"><Link to={"/product/" + item.id}><img rel="preload" as="image" src={`https://sgtback.pythonanywhere.com` + item.image1} alt="icon_slider" decoding="auto" /></Link></div>
                        <div className='cards_bottom'>
                          <div style={{ marginBottom: '5px' }}><Link to={`/product/` + item?.id}><span>{item.name}</span></Link></div>
                          <div><Link to={`/product/` + item?.id}><p>{Math.round(item.price_uzs).toLocaleString()} cум</p></Link><img rel="preload" as="image" src={item.likes ? Correct : buybox} alt="icon_slider" decoding="auto" onClick={() => addCards(item, t("..qoshildi"))} /></div>
                          <Link to={"/product/" + item.id} style={{ textDecoration: 'none' }}><MyButton>{t("..buybutton")}</MyButton></Link>
                        </div>
                      </div>
                    )
                  })
                ) : (<div className='empty_product'>
                  <img src={shopping_cart} alt="cart"/>
                  <h2>{t("..catsearch")}</h2>
                </div>)}
              {status === 'loading' ? null : null || error ? null : 
                <div className="pagination">
                  <Pagination pages={howManyPages} setCurrentPage={setCurrentPage} />
                </div>
                }
            </div>
          </div>
        </div>
      </div>
    </>
  )
};

export default Category;
