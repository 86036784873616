import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import PcFotoStart from '../../images/pngegg (4) 1mypc.png'
import MyButton from '../../components/buttons/Button'
import './mtcomputer.css'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Infirma from '../../images/pngegg (4) 1infirma.png'

const MyPc = () => {
  const { t } = useTranslation()
  return (
    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', background: "black" }}>
      <div className='container my_pc_start' id='things'>
        <div className="row pc_start"
        >
          <div className="col-lg-6 col-md-6 col-sm-5 left">
            <div><LazyLoadImage rel="preload" as="image" src={PcFotoStart} alt="icon_slider" decoding="auto" /></div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-7 right">
            <h3>{t("..pcyeging")}</h3>
            <span>{t("..kompyuteryeging1")}</span>
            <Link to="/makepc" style={{ textDecoration: 'none' }}><MyButton style={{ paddingLeft: '25px', paddingRight: '25px' }}>{t("..boshlamoq")}</MyButton></Link>
          </div>
        </div>
        <div className="row pc_start"
        >
          <div className="col-lg-6 col-md-6 col-sm-5 left">
            <div><LazyLoadImage rel="preload" as="image" src={Infirma} alt="icon_slider" decoding="auto" /></div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-7 right">
            <h3>{t("..Fermangizni")}</h3>
            <span>{t("..kompyuteryeging2")}</span>
            <Link to="/makefirma" style={{ textDecoration: 'none' }}><MyButton style={{ paddingLeft: '25px', paddingRight: '25px' }}>{t("..boshlamoq")}</MyButton></Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MyPc
